<template lang="pug">
v-card(light)
    v-card-text()
        chart(
            :options="flowChartOptions"
        )
</template>

<script lang="js">

import { Chart } from 'highcharts-vue';

export default {
    name: 'FlowChart',
    components: {
        chart: Chart
    },
    props: {
        seriesData: {
            type: Array,
            required: false,
            default: () => []
        },
        name: {
            type: String,
            required: false,
        }
    },
    data () {
        return {
            flowChartOptions: {
                credits: { enabled: false },
                chart: {
                    type: 'line'
                },
                title: {
                    text: '',
                    align: 'left'
                },
                yAxis: {
                    title: {
                        text: 'Millions GPM'
                    }
                },
                xAxis: {
                    title: {
                        text: 'Hour'
                    },
                    categories: Array.from({ length: 24 }, (_, i) => i),
                    labels: {
                        formatter: function () {
                            let hour = this.value;
                            let amPm = hour < 12 ? 'AM' : 'PM';
                            hour = hour % 12;
                            hour = hour || 12; // replace 0 with 12 for 12 AM
                            return `${hour} ${amPm}`;
                        }
                    }

                },
                tooltip: {
                    shared: true,
                    useHTML: true,
                    formatter: function () {
                        let hour = this.x; // assuming `this.x` contains the hour in 0-23 format
                        let amPm = hour < 12 ? 'AM' : 'PM';
                        hour = hour % 12;
                        hour = hour || 12; // replace 0 with 12 for 12 AM
                        let s = `<br><h3>Time: ${hour} ${amPm}<h3><br>`;
                        s += '<table>';
                        this.points.forEach(function (point) {
                            s += `<tr>` +
                                    `<td>` +
                                        `<span style="color:${point.color}">${point.series.name}:<span>
                                    </td>` +
                                    `<td style="text-align: right; padding-left: 10px;">` +
                                        `<span style="color:${point.color}">${point.y}</span>` +
                                    `</td>` +
                                `</tr>`;
                        });
                        s += '</table>';

                        return s;
                    }
                },
                plotOptions: {
                    series: {
                        marker: {
                            enabled: false // Disables the display of the markers for all series
                        }
                    }
                },
                series: []
            }
        };
    },
    watch: {
        seriesData: {
            handler (newVal, oldVal) {
                console.log('seriesData updated', this.name, newVal) ;
                this.$set(this.flowChartOptions, 'series', newVal);
            },
            deep: true
        }
    },
    methods: {
        init (){
            this.flowChartOptions.title.text = this.name;
            if(this.seriesData?.length > 0){
                this.$set(this.flowChartOptions, 'series', this.seriesData);
            } else {
                this.$set(this.flowChartOptions, 'series', []);
            }
        }
    },
    // select a lice cycle method to populate the chart data
    mounted () {
        this.init();
    },

};
</script>
